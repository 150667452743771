<template>
  <div>
    <Header />

    <section class="mt-24 lg:mt-20 font-source">
      <div class="bg-darkGreen px-4 lg:px-32 py-12">
        <h3 class="text-2xl lg:text-6xl text-white">
          <span class="font-light">Welcome to KARES</span><br />
          <span class="font-bold">Transaction Tracking Portal</span>
        </h3>

        <form
          @submit.prevent="trackCode"
          class="bg-white border border-ansGreen rounded w-9/12 lg:w-5/12 flex justify-between p-1 lg:p-2 mt-6"
        >
          <input
            :disabled="processing"
            id="trackInput"
            type="text"
            autofocus
            placeholder="Input a tracking code here"
            v-model="form.trackingCode"
            @keyup.enter="trackCode"
          />
          <button
            type="submit"
            :disabled="processing || !form.trackingCode"
            class="px-4 py-3 lg:py-4 bg-ansGreen text-white rounded flex items-center"
          >
            <i class="fas fa-long-arrow-alt-right"></i>
          </button>
        </form>
      </div>

      <!-- Output area -->
      <div class="bg-white px-4 lg:px-32 py-12">
        <!-- History area -->
        <div
          v-if="!historyFetched && processing"
          class="flex justify-center items-center h-[30vh]"
        >
          <spinner color="ansGreen" class="w-18 h-18" />
        </div>

        <div
          v-else-if="!historyFetched && !processing"
          class="flex justify-center items-center h-[30vh]"
        >
          <p class="italic text-gray-400 text-center text-xs lg:text-base">
            Enter tracking code to see transaction history...
          </p>
        </div>

        <div v-else>
          <div class="flex justify-between text-xs lg:text-xl mb-10">
            <h5 class="font-bold">Transaction history</h5>
            <h5 class="text-cadet">
              <span>Tracking code: </span>
              <span class="font-bold">{{ history.trackingId }}</span>
            </h5>
          </div>

          <InvoiceCard
            :invoiceData="history.invoiceData"
            @showview="openInvoice"
          />

          <PaymentCard
            :paymentData="{
              done: history.invoiceData.invoiceStatus == 'paid',
              ...history.transactionData,
            }"
          />

          <ReceiptCard
            @showview="openReceipt"
            @generaterecipt="showGenerate = true"
            :receiptData="{
              done: history.invoiceData.invoiceStatus == 'paid',
              receipt: history.receiptData,
            }"
          />
        </div>
      </div>
    </section>

    <!-- Footer section -->
    <Footer />

    <!-- Modal section -->
    <Modal v-if="showInvoice" v-model="showInvoice" size="lg">
      <Invoice :invoiceNumber="invoiceNumber" />
    </Modal>

    <Modal v-if="showReceipt" v-model="showReceipt" size="lg">
      <Receipt :receiptNumber="receiptNumber" />
    </Modal>

    <Modal v-if="showGenerate" v-model="showGenerate" size="md">
      <GenerateReceipt
        :invoiceNumber="invoiceNumber"
        @generatedone="trackCode"
        @hideForm="hideGenerate"
      />
    </Modal>
  </div>
</template>

<script>
import Header from "@/components/app/Header.vue";
import InvoiceCard from "@/components/track/InvoiceCard.vue";
import PaymentCard from "@/components/track/PaymentCard.vue";
import ReceiptCard from "@/components/track/ReceiptCard.vue";
import Footer from "@/components/app/Footer.vue";
import Modal from "@/components/app/Modal.vue";
import Invoice from "@/components/home/Invoice.vue";
import Receipt from "@/components/home/Receipt.vue";
import GenerateReceipt from "@/components/app/GenerateReceipt.vue";
export default {
  name: "Track",

  components: {
    Header,
    InvoiceCard,
    PaymentCard,
    ReceiptCard,
    Footer,
    Modal,
    Invoice,
    Receipt,
    GenerateReceipt,
  },

  data() {
    return {
      processing: false,
      historyFetched: false,
      showReceipt: false,
      showInvoice: false,
      showGenerate: false,
      invoiceNumber: null,
      receiptNumber: null,
      form: {
        trackingCode: null,
      },
      history: {
        trackingId: null,
        invoiceData: null,
        receiptData: null,
      },
      paymentData: null,
    };
  },

  methods: {
    async trackCode() {
      try {
        this.showGenerate = false;
        if (!this.form.trackingCode) {
          return;
        }

        this.processing = true;

        const res = await this.$http.post(
          "/track",
          {
            trackingId: this.form.trackingCode,
          },
          {
            headers: { noauth: true },
          }
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.history = data;
        this.invoiceNumber = this.history.invoiceData.invoiceNumber;
        this.historyFetched = true;
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },

    openInvoice(num) {
      this.invoiceNumber = num;
      this.showInvoice = true;
    },

    openReceipt(num) {
      this.receiptNumber = num;
      this.showReceipt = true;
    },

    hideGenerate() {
      this.showGenerate = false;
    },
  },
};
</script>

<style scoped>
#trackInput {
  @apply placeholder-cadet focus-within:outline-none;
  width: calc(100% - 48px);
}
</style>

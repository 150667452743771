<template>
  <div class="">
    <Header />

    <!-- Banner section -->
    <section
      id="banner"
      class="mt-14 lg:mt-20 h-99 lg:h-h44 w-full pl-6 pr-2 lg:pl-32 flex flex-col justify-center items-start font-source text-white"
    >
      <h3 class="text-3pxl lg:text-6xl font-light">
        Pay your revenue so<br />
        we
        <span style="text-shadow: 4px 1px #155135" class="font-bold">
          can grow together!
        </span>
      </h3>

      <p class="text-sm lg:text-2xl mt-4">
        Pay your revenue online, All with the click of a button.
      </p>

      <button
        @click="showRevForm"
        class="bg-white text-ansGreen px-6 py-4 font-semibold mt-12 rounded text-base lg:text-2xl"
      >
        I want to make revenue payment
      </button>
    </section>

    <!-- Info Section -->
    <section class="bg-white pt-10 lg:pt-28 px-4 lg:px-32 font-source">
      <div class="flex justify-between">
        <div>
          <h3 class="text-ansBlack text-2xl lg:text-4xl font-semibold">
            How to pay your tax
          </h3>
          <h5 class="text-cadet text-base lg:text-2xl mt-1.5">
            You can do so easily with three simple steps
          </h5>
        </div>

        <div class="hidden lg:block">
          <img src="@/assets/images/icons/tax_process.svg" alt="icons" />
        </div>
      </div>

      <div class="flex justify-between gap-7 lg:gap-40 mt-11 mb-16 lg:my-32">
        <div class="max-w-xs">
          <img
            src="@/assets/images/icons/form.svg"
            alt="icons"
            class="h-6 w-6 lg:h-10 lg:w-10"
          />

          <h4 class="text-ansBlack mt-3 text-base">Provide details</h4>

          <p class="text-cadet mt-2 text-sm">
            Provide your phone number, NIN, email and address
          </p>
        </div>

        <div class="max-w-2xs">
          <img
            src="@/assets/images/icons/mouse.svg"
            alt="icons"
            class="h-6 w-6 lg:h-10 lg:w-10"
          />

          <h4 class="text-ansBlack mt-3 text-base">Select tax</h4>

          <p class="text-cadet mt-2 text-sm">
            Select the tax you would like to pay for.
          </p>
        </div>

        <div class="max-w-xs">
          <img
            src="@/assets/images/icons/invoice.svg"
            alt="icons"
            class="h-6 w-6 lg:h-10 lg:w-10"
          />

          <h4 class="text-ansBlack mt-3 text-base">Generate invoice</h4>

          <p class="text-cadet mt-2 text-sm">
            An invoice will be generated for your to make payment with
          </p>
        </div>
      </div>

      <div class="flex justify-center pb-22">
        <button
          @click="showRevForm"
          class="text-ansGreen font-semibold bg-ansLemon py-4 lg:py-5 px-5 lg:px-10 rounded text-base lg:text-2xl"
        >
          I want to make revenue payment
        </button>
      </div>
    </section>

    <!-- Footer section -->
    <Footer />

    <Modal
      v-if="$store.state.general.showForm"
      :value="showForm"
      :size="modalSize"
      mt="7"
    >
      <GenerateInvoice
        v-if="$store.state.general.step <= 5"
        @doned="generateDone"
      />
      <Invoice
        :invoiceNumber="this.$store.getters['general/ind'].invoiceNumber"
        v-if="$store.state.general.step == 6"
      />
    </Modal>
  </div>
</template>

<script>
import Header from "@/components/app/Header.vue";
import Footer from "@/components/app/Footer.vue";
import Modal from "@/components/app/Modal.vue";
import GenerateInvoice from "@/components/home/GenerateInvoice.vue";
import Invoice from "@/components/home/Invoice.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Modal,
    GenerateInvoice,
    Invoice,
  },
  data() {
    return {
      showForm: this.$store.state.general.showForm,
    };
  },
  computed: {
    modalSize() {
      return this.$store.state.general.step == 6 ? "lg" : "md";
    },
  },
  mounted() {
    const step = this.$route.query.st ? Number(this.$route.query.st) : 1;
    this.$store.commit("general/updateStep", step);
  },
  methods: {
    showRevForm() {
      this.$store.commit("general/updateForm", true);
    },
    generateDone() {
      this.next();
    },
    next() {
      const step = this.$store.state.general.step + 1;
      this.$store.commit("general/updateStep", step);
      this.$router.push({
        name: "Home",
        query: { st: step },
      });
    },
  },
};
</script>
<style scoped>
#banner {
  background-color: #155035;
  background-image: url(../assets/images/banner.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 50%;
}
</style>

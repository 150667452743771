<template>
  <div>
    <Header />

    <section class="block lg:flex justify-between font-source mt-24 lg:mt-20">
      <div
        id="side-banner"
        class="hidden lg:flex w-1/2 flex-col justify-center items-start pl-32 pr-4 text-white"
      >
        <h3 class="text-5xl font-light -mt-32">
          Pay your revenue so<br />
          we <span class="font-bold">can grow together!</span>
        </h3>

        <p class="text-xl mt-4">
          Pay your revenue online, All with the click of a button.
        </p>
      </div>

      <!-- Form side -->
      <div class="w-full lg:w-1/2 px-4 lg:px-24 mt-13 lg:mt-32">
        <h3 class="text-ansBlack font-semibold text-2xl lg:text-4xl">
          Welcome to KARES
        </h3>
        <h5 class="text-cadet text-base lg:text-2xl mt-0 lg:mt-2">
          Login to continue
        </h5>

        <!-- Login form -->
        <section class="mt-8">
          <div>
            <label for="emailOrPhone">Username</label>
            <input
              id="emailOrPhone"
              type="text"
              class="input"
              placeholder="Enter email or phone"
              v-model="form.emailOrPhone"
            />
            <small v-if="!emailOrPhoneReady" class="text-red-400">
              Enter valid email or phone
            </small>
          </div>

          <div class="mt-4">
            <label for="password">Password</label>
            <input
              id="password"
              type="password"
              class="input"
              placeholder="Enter password"
              v-model="form.password"
            />
          </div>

          <div class="mt-13">
            <button
              :disabled="processing || !loginReady"
              @click="login"
              class="bg-ansGreen text-white w-full py-3.5 font-semibold rounded flex gap-2 items-center justify-center"
            >
              <span>Login</span>
              <spinner v-if="processing" />
            </button>
          </div>
        </section>

        <section class="mt-1">
          <div class="flex justify-center mt-10">
            <router-link
              :to="{ name: 'Forgot Password' }"
              class="text-ansGreen text-sm"
            >
              Forgot Password
            </router-link>
          </div>

          <div class="flex justify-center mt-16 lg:mt-20 text-cadet text-sm">
            © {{ year }} - KARES. All rights reserved.
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "@/components/app/Header.vue";
export default {
  name: "Login",

  components: {
    Header,
  },

  data() {
    return {
      processing: false,
      form: {
        emailOrPhone: null,
        password: null,
      },
    };
  },

  mounted() {
    this.$store.commit("general/logout");
  },

  computed: {
    year() {
      return new Date().getFullYear();
    },
    emailOrPhoneReady() {
      return (
        (this.form.emailOrPhone &&
          (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.form.emailOrPhone
          ) ||
            /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.emailOrPhone))) ||
        !this.form.emailOrPhone
      );
    },
    loginReady() {
      return (
        this.form.emailOrPhone && this.emailOrPhoneReady && this.form.password
      );
    },
  },

  methods: {
    async login() {
      if (!this.loginReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all input",
        });
        return;
      }

      try {
        this.processing = true;

        const res = await this.$http.post("/auth/login", this.form, {
          headers: { noauth: true },
        });

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.user,
          token: data.token,
        });
        this.$store.commit("general/login");

        if (this.$route.query.nextUrl) {
          this.$router.push(this.$route.query.nextUrl);
          return;
        }

        if (data.user.userTypeId == "321ac5435be36a9552c1198b") {
          this.$router.push({ name: "Admin Dashboard" });
        } else {
          this.$router.push({ name: "Agent Dashboard" });
        }
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
#side-banner {
  background-color: #155035;
  background-image: url(../assets/images/banner.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 50%;
  height: calc(100vh - 80px);
}
</style>
